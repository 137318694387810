.cloudContainer {
  width: 100%;
  height: 100%;
  min-width: 500px;
  min-height: 500px;
  margin-left: -20px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  opacity: .15;
}

#cloud-intro {
  position: relative;
  height: 100%;
  background: url(../public/clouds1000.png);
  background: url(../public/clouds1000.png) 0 200px, url(../public/clouds1200_1.png) 0 300px, url(../public/clouds1000_blur3.png) 100px 250px;
  animation: wind 30s linear infinite;
}

.cloud-intro-light {
  filter: invert(60%);
}

@keyframes wind {
  0% {
    background-position: 1000px 200px, 1200px 300px, 1100px 250px;
  }

  100% {
    background-position: 0 200px, 0 300px, 100px 250px;
  }

}