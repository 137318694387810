.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.grid {
  margin: 10px 0px;
  flex-wrap: wrap;
  display: flex;
  background: transparent;
}

.card {
  margin-left: 10px;
  margin-bottom: 10px;
  min-width: 350px;
  max-width: 350px;
}

.card .MuiCardContent-root:last-child {
  padding-bottom: 16px;
}

.card-Light {
  box-shadow: 1px 1px 1px #333;
}

.card-Dark:nth-of-type(5n+1) {
  box-shadow: 1px 1px 1px #9ffcc155;
}

.card-Dark:nth-of-type(5n+2) {
  box-shadow: 1px 1px 1px #9ffcc155;
}

.card-Dark:nth-of-type(5n+3) {
  box-shadow: 1px 1px 1px #9ffcc155;
}

.card-Dark:nth-of-type(5n+4) {
  box-shadow: 1px 1px 1px #9ffcc155;
}

.card-Dark:nth-of-type(5n+5) {
  box-shadow: 1px 1px 1px #9ffcc155;
}

.card-Halloween {
  box-shadow: 1px 1px 1px purple;
}

.card-Christmas:nth-of-type(2n+1) {
  box-shadow: 1px 1px 1px red;
}

.card-Christmas:nth-of-type(2n+2) {
  box-shadow: 1px 1px 1px green;
}

.smallCardTitle {
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.panel {
  display: flex;
}

.blindsSlider {
  padding: 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa'), url(./fonts/Comfortaa-Regular.ttf) format('truetype');
}

/* climate panel  */
@keyframes heatingFrames {
  to {
    box-shadow: 1px 0px 2px #fad339 inset, 1px 0 3px #fad339 inset, 0px 0 4px #cc3910 inset,
      0px 0 5px #fad339 inset, 0px 0 2px #fad339 inset, 0px 0 3px #cc3910 inset, 0px 0 4px #fad339 inset,
      0px 0 5px #cc3910 inset;

  }

  from {
    box-shadow: 0px 0 5px #cc3910 inset, 0px 0 10px #fad339 inset, 0px 0 15px #cc3910 inset, 0px 0 10px #cc3910 inset,
      0px 0 2px #cc3910 inset, 0px 0 3px #cc3910 inset, 0px 0 4px #cc3910 inset, 0px 0 5px #cc3910 inset;
    ;
  }
}

@keyframes coolingFrames {
  to {
    box-shadow: 1px 0px 2px #5190ad inset, 1px 0 3px #5190ad inset, 0px 0 4px #214b7a inset,
      0px 0 5px #5190ad inset, 0px 0 2px #5190ad inset, 0px 0 3px #214b7a inset, 0px 0 4px #5190ad inset,
      0px 0 5px #214b7a inset;

  }

  from {
    box-shadow: 0px 0 5px #214b7a inset, 0px 0 10px #5190ad inset, 0px 0 15px #214b7a inset, 0px 0 10px #214b7a inset,
      0px 0 2px #214b7a inset, 0px 0 3px #214b7a inset, 0px 0 4px #214b7a inset, 0px 0 5px #214b7a inset;
    ;
  }
}

/* climate panel  */

/* light panel  */
.sun {
  pointer-events: none;
  width: 30rem;
  height: 20rem;
  margin: 3rem auto;
  position: absolute;
  left: -60px;
  bottom: -280px;
  background: radial-gradient(transparent 50%, white), radial-gradient(yellow, transparent 70%);
  -webkit-mask-image: radial-gradient(rgba(0, 0, 0, 1.0) 40%, transparent 65%);
  mask-image: radial-gradient(rgba(0, 0, 0, 1.0) 40%, transparent 65%);
  border-radius: 50%;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    border-radius: 50%;
    -webkit-mask-image: radial-gradient(rgba(0, 0, 0, 1.0) 40%, transparent 65%);
    mask-image: radial-gradient(rgba(0, 0, 0, 1.0) 40%, transparent 65%);
  }

  &::before {
    background: repeating-conic-gradient(from 0deg, yellow 0deg 20deg, transparent 20deg 40deg);
    animation: rotate 720s linear, scale 3s linear infinite;
  }

  &::after {
    background: radial-gradient(orange, transparent 27%, transparent calc(27% + 3px) 100%), radial-gradient(gold, transparent 70%), repeating-conic-gradient(from 0deg, gold 0deg 5deg, transparent 5deg 10deg);
    transform: rotate(15deg);
    animation: rotate 360s linear;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(0.8);
  }

  60% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}