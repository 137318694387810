.rainContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.rain {
  background: white;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #63c5ff 100%);
  height: 50px;
  position: absolute;
  width: 1px;
}

.rainThick {
  width: 2px;
}

$rain: 150;

@for $i from 1 through $rain {
  $top: (
    random(50) + 50) * 1%;
  $left: random(100) * 1%;
  $opacity: (
    random(30) + 30) * 0.01;
  $delay: random(20) - 1s;

  .rain:nth-of-type(#{$i}) {
    animation-name: rain-#{$i};
    animation-delay: $delay;
    animation-duration: random(6) + 4s;
    animation-iteration-count: infinite;
    left: $left;
    opacity: $opacity;
    top: -$top;
  }

  @Keyframes rain-#{$i} {
    from {
      left: $left;
      opacity: $opacity;
      top: -$top;
    }

    to {
      opacity: 0;
      top: $top + 40%;
    }
  }
}